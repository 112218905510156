import React from 'react';

export type AccountType = "Internal" | "External";

export interface IAuthContext {
    isAuthenticated: boolean;
    env?: string;
    name?: string;
    email?: string;
    clientIds?: number[];
    blobBaseURL?: string;
    site?: string;
    type?: string;
    tid?: string;
    role: string;
    isPHAEditor?: boolean;
    isInternalUser?: boolean;
    isExternalUser?: boolean;
    isAdmin?: boolean;
    isSubAdmin?: boolean;
    azureMaps?: { clientId: string };
    fullCalendar?: { licenseKey: string };
}

const defaultContext: IAuthContext = {
    isAuthenticated: false,
    role: 'Reader'
}

export const AuthContext = React.createContext<IAuthContext>(defaultContext);
export const AuthConsumer = AuthContext.Consumer;