import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IEmployee, IResEngine, ISchedule, IScheduleDetail } from "api/models";
import { GetScheduleDetail } from "api/resourceSchedulingAPI";
import dateFormat from "dateformat";

interface IResourceSchedulingState {
    /*true:week, false:month*/
    weekView: boolean,
    employeeExpand?: boolean,
    engines?: IResEngine[],
    currentSchedule?: ISchedule,
    currentStaffing?: number[],
    personalList?: IEmployee[],
    allPersonnel?: IEmployee[],
    engineFilter?: any,
    resourceSortBy: string,
    employeeFilter?: {
        searchKey?: string,
        sortBy?: string
    },
    assignedPersonId?: number[],
}
const initialState: IResourceSchedulingState = {
    weekView: true,
    engineFilter: {},
    resourceSortBy: 'id',
    allPersonnel: []
}

export const selectSchedule = createAsyncThunk('actions/selectSchedule', async (schedule: ISchedule) => {
    let scheduleDetail = {} as IScheduleDetail;
    if (schedule?.scheduleId && schedule.scheduleId > 0) {
        scheduleDetail = await GetScheduleDetail(schedule.scheduleId);
    }
    if (scheduleDetail && (scheduleDetail.scheduleId ?? 0) > 0) {
        return scheduleDetail;
    }
    return schedule;
});

const resourceSchedulingActions = createSlice({
    name: "resourceSchedulingActions",
    initialState,
    reducers: {
        setWeekView: (state, action) => {
            state.weekView = action.payload;
            if (!action.payload) {
                state.employeeExpand = false;
            }
        },
        setEmployeeExpand: (state, action) => {
            state.employeeExpand = action.payload;
        },
        setEngines: (state, action) => {
            state.engines = action.payload;
        },
        setCurrentSchedule: (state, action) => {
            state.currentSchedule = action.payload;
            state.currentStaffing = action.payload?.staffing?.map((s: any) => s.crewId);
        },
        setCurrentStaffing: (state, action) => {
            state.currentStaffing = action.payload;
        },
        setPersonalList: (state, action) => {
            state.personalList = action.payload;
        },
        setAllPersonnel: (state, action) => {
            state.allPersonnel = action.payload;
        },
        setEngineFilter: (state, action) => {
            state.engineFilter = action.payload;
        },
        setEmployeeFilter: (state, action) => {
            state.employeeFilter = action.payload;
        },
        setAssignedPersonId: (state, action) => {
            state.assignedPersonId = action.payload;
        },
        setResourceSortBy: (state, action) => {
            state.resourceSortBy = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(selectSchedule.fulfilled, (state, action) => {
                if ('scheduling' in action.payload) {
                    let scheduleDetail = action.payload;
                    //IScheduleDetail
                    state.currentSchedule = {
                        scheduleId: scheduleDetail.scheduleId,
                        engineId: scheduleDetail.engineId,
                        engine: state.engines?.find(e => e.id === scheduleDetail.engineId) as IResEngine,
                        fireId: scheduleDetail.assignment?.fire,
                        rcc: scheduleDetail.assignmentDetails?.rcc,
                        taskForceId: scheduleDetail.assignmentDetails?.taskForceId,
                        clientId: scheduleDetail.assignmentDetails?.clients,
                        superVisorId: scheduleDetail.assignmentDetails?.fieldSupervisor,
                        fieldSupervisorModel: scheduleDetail.assignmentDetails?.fieldSupervisorModel,
                        startDate: scheduleDetail.scheduling?.onAssignment?.startDate,
                        endDate: scheduleDetail.scheduling?.onAssignment?.endDate,
                        holdStartDate: scheduleDetail.scheduling?.onHold?.startDate,
                        holdEndDate: scheduleDetail.scheduling?.onHold?.endDate,
                        assignment: scheduleDetail.assignment?.assignmentChosen,
                        personals: scheduleDetail.staffing,
                        comment: scheduleDetail.comments,
                        resourceOrderId: scheduleDetail.details?.ro,
                        lat: scheduleDetail.assignment?.lat,
                        long: scheduleDetail.assignment?.lon,
                        city: scheduleDetail.assignment?.city,
                        state: scheduleDetail.assignment?.state,
                        recurring: scheduleDetail.isRecurring,
                    } as ISchedule;
                    state.currentStaffing = scheduleDetail?.staffing?.map((s: any) => s.crewId);
                } else {
                    //ISchedule
                    let schedule = action.payload as ISchedule;
                    if (schedule.startDate?.length) {
                        let aStart = new Date(schedule.startDate);
                        if (aStart.getHours() == 0 && aStart.getMinutes() == 0) {
                            aStart.setMinutes(30, 0, 0);
                        }
                        schedule.startDate = dateFormat(aStart, 'yyyy-mm-dd HH:MM:ss');
                    }
                    if (schedule.endDate?.length) {
                        let aEnd = new Date(schedule.endDate);
                        if (aEnd.getHours() == 0 && aEnd.getMinutes() == 0) {
                            aEnd.setHours(23, 30, 0, 0);
                        }
                        schedule.endDate = dateFormat(aEnd, 'yyyy-mm-dd HH:MM:ss');
                    }
                    state.currentSchedule = { ...schedule, personals: [], engine: state.engines?.find(e => e.id === schedule.engineId) }
                    state.currentStaffing = undefined;
                }
            })
    },
});

export const {
    setWeekView,
    setEmployeeExpand,
    setEngines,
    setCurrentSchedule,
    setCurrentStaffing,
    setPersonalList,
    setAllPersonnel,
    setEngineFilter,
    setEmployeeFilter,
    setAssignedPersonId,
    setResourceSortBy
} = resourceSchedulingActions.actions;

export default resourceSchedulingActions.reducer;