import React, { PropsWithChildren } from "react";
import { useCookies } from "react-cookie";

import { AuthContext, IAuthContext } from "./AuthContext";
import { AUTH_COOKIE, AUTH_EDITOR } from "utils/consts";
import { Constants } from "utils/constants";
import { isString } from "utils";

export const AuthProvider = ({ children }: PropsWithChildren<{}>): React.ReactElement => {
    const [cookies] = useCookies([AUTH_COOKIE]);

    let ctx: IAuthContext = { isAuthenticated: false, role: 'Reader' };
    if (cookies[AUTH_COOKIE]) {
        const { env, name, clientIds, blobBaseURL, email, tid, role, type, site, azureMaps, fullCalendar } = cookies[AUTH_COOKIE];
        ctx = {
            env,
            name: name || email,
            clientIds,
            blobBaseURL,
            site,
            email,
            tid,
            role,
            type,
            isAuthenticated: !!email,
            isInternalUser: (tid == '6fb6d4a2-2572-45cc-be58-41dc2abeb290' && type == 'Member') || false,
            isExternalUser: (tid != '6fb6d4a2-2572-45cc-be58-41dc2abeb290' || type == 'Guest') || false,
            azureMaps,
            fullCalendar
        }
    }

    return <AuthContext.Provider value={ctx}>
        {children}
    </AuthContext.Provider>;
}