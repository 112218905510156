import { IFireCurrentWeather, IFireForecastedWeather, IFireWeatherParam, IFireInfoUpdate, IDuplicateFire, IDuplicateFireCoords, IFireNavigation } from "./models";
import { getJson, postJson, postWithFile } from "utils";


export async function getCurrentWeather(weatherParam: Partial<IFireWeatherParam>): Promise<IFireCurrentWeather> {
    return postJson('/api/fde/fires/GetCurrentWeather', weatherParam);
}

export async function getForecastedWeather(weatherParam: Partial<IFireWeatherParam>): Promise<IFireForecastedWeather> {
    return postJson('/api/fde/fires/GetForecastedWeather', weatherParam);
}

export async function uploadKmlKmz(formData: FormData): Promise<any> {
    return postWithFile('/api/fde/fires/UploadKmlKmz', formData);
}

export async function saveFire(fireSaveViewModel: any): Promise<number> {
    return postJson('/api/fde/fires/SaveFire', fireSaveViewModel);
}

export async function getResFire(id: number): Promise<IFireInfoUpdate> {
    return getJson(`/api/fde/fires/GetResFire/${id}`);
}

export async function getDuplicateFire(inputs: Partial<IDuplicateFireCoords>): Promise<IDuplicateFire> {
    return postJson('/api/fde/fires/GetDuplicateFire', inputs);
}

export async function checkFireNavigation(fireId: number): Promise<IFireNavigation> {
    return getJson(`/api/fde/fires/CheckFireNavigation/${fireId}`);
}
export async function toggleNoteworthyFire(fireId: number): Promise<IFireNavigation> {
    return getJson(`/api/fde/fires/ToggleNoteworthyFire/${fireId}`);
}
export async function getFirePerimeters(fireId: number): Promise<any> {
    return getJson(`/api/fde/fires/GetFirePerimeters/${fireId}`);
}
export async function getPerimeterCenter(geog: number): Promise<any> {
    return postJson(`/api/fde/fires/GetPerimeterCenter`, { Geography: geog });
}