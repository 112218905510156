import { getRiskUrl } from "api/riskAPI";
import { IRisk } from "../../api/models/IRisk";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface RiskState {
  api_url: string;
  access_token: '';
  api_response: IRisk;
  address: '';
  coords: {
    lat: '',
    lon: ''
  };
  past_scores: [];
  risk_call_done: boolean;
}

const initialState: RiskState = {
  api_url: "https://dashboard.wildfire-defense.com",
  access_token: '',
  api_response: {
    error: '',
    data: {
      error: '',
      risk_version: '',
      score_v: '',
      score_wds: '',
      score_wds_text: '',
      score_whp: '',
      state_mean: '',
      std_dev: '',
      std_dev_text: ''
    }
  },
  address: '',
  coords: {
    lat: '',
    lon: ''
  },
  past_scores: [],
  risk_call_done: false
}

export const fetchRiskUrl = createAsyncThunk('riskActions/fetchRiskUrl', async () => {
  let response = await getRiskUrl();
  return response || "";
});

export const riskSlice = createSlice({
  name: "risk",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    setApiResponse: (state, action) => {
      state.api_response = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setCoords: (state, action) => {
      state.coords = action.payload;
    },
    setPastRiskScores: (state, action) => {
      state.past_scores = action.payload;
    },
    setRiskCallDone: (state, action) => {
      state.risk_call_done = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRiskUrl.fulfilled, (state, action) => {
      state.api_url = action.payload;
    });
  },
});

/** Action creators are generated for each case reducer function */
export const {
  setAccessToken,
  setApiResponse,
  setAddress,
  setCoords,
  setPastRiskScores,
  setRiskCallDone
} = riskSlice.actions;

export default riskSlice.reducer;